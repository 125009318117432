<template>
  <div>
    <div class="mass-action-settings">

      <el-select
        v-model="actionData.chief_type"
        style="width:350px"
        :placeholder="templateData.select_placeholder || 'Выберите тип руководителя/администратора'"
        size="small"
      >
        <el-option
          v-for="type in $companyConfiguration.user.getChiefTypes()"
          :key="type"
          :label="$companyConfiguration.user.getChiefTypeShowName(type)"
          :value="type"
        >
        </el-option>
      </el-select>






      <el-popconfirm
        style="margin-left: 20px"
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить орг. структуру?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Запустить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import AddUsersDialog from "@/components/user/AddUsersDialog.vue";

export default {
  name: "user-remove-chief-action",
  mixins: [ActionTemplateMixin],
  components: {AddUsersDialog, MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.chief_type;
    }
  },
  data() {
    return {
      user: {},
      actionData: {
        chief_type: null,
      }
    }
  },
  methods: {},
}
</script>
